// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cashbacks-js": () => import("./../../../src/pages/cashbacks.js" /* webpackChunkName: "component---src-pages-cashbacks-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-retailer-js": () => import("./../../../src/templates/retailer.js" /* webpackChunkName: "component---src-templates-retailer-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

